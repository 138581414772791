import React from "react";
import { Row, Col } from "react-bootstrap";
import { WarningOutlined } from '@ant-design/icons';

export const getOutageMessagesUIComponent = (msg, index, onClose) => {
    return <div style={{ textAlign: "center" }} key={index}>
        <div className="alertboxheader">
            <Row style={{ textAlign: 'center', width: '100%' }}>
                <Col xs={11} >
                    <WarningOutlined /> &nbsp; {msg.title} : <span dangerouslySetInnerHTML={{ __html: msg.body }} />
                </Col>
                <Col xs={1} style={{ textAlign: 'right' }}>
                    <button
                        class="close-button"
                        type="button" aria-label="close"
                        onClick={onClose}
                    >
                        X
                    </button>
                </Col>
            </Row>
        </div>
    </div>
}