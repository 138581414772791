import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
  dashboardValue: [],
  recentChecks: [],
  pendingPayableReports: [],
  customerServieReport: {},
  vendorDownloadLinks: [],
  loginMsg:"",
  recentLoader:false,
  dailyLoader:false,
  csLoader:false,
  vendorLoader:false

};

const DashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_DATA:
      return { ...state, dashboardValue: action.payload };
    case actionTypes.SET_RECENT_CHECKS:
      return { ...state, recentChecks: action.payload };
    case actionTypes.SET_PENDING_PAYABLE_CHECK:
      return { ...state, pendingPayableReports: action.payload };
    case actionTypes.SET_CUSTOMER_SERVICE:
      return { ...state, customerServieReport: action.payload };
    case actionTypes.VENDOR_DOWNLOAD_LINK_SUCCESS:
      return { ...state, vendorDownloadLinks: action.payload };
    case actionTypes.LOGIN_MSG:
      return { ...state, loginMsg: action.payload };
    case actionTypes.RECENT_LOADER:
      return { ...state, recentLoader: action.payload };
    case actionTypes.DAILY_LOADER:
      return { ...state, dailyLoader: action.payload };
    case actionTypes.CS_LOADER:
      return { ...state, csLoader: action.payload };
    case actionTypes.VENDOR_LOADER:
      return { ...state, vendorLoader: action.payload };
    case actionTypes.OUTAGE_MSG:
      return { ...state, outageMsg: action.payload };
    default:
      return state;
  }
};

export default DashboardReducer;
