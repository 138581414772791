
import * as actions from "../actions/loginAction";
import cookie from "react-cookies";
import { environment } from "../../common/sysco-ui-components/config/constant";
import { DASHBOARD } from "../../config/path";
import history from "../../utils/history";
import { API_URL_SW, authorization, DP_ENV, authorizationJwt } from "../../config/apiConfig";
import Axios from "axios";
import { store } from "../..";
import { LOGIN_MSG, OUTAGE_MSG } from "../actions/actionTypes";
import { Loader } from "../../common/sysco-ui-components/services/commonServices";
import { CAPTCHAFAILED_MESSAGE} from '../../config/string';

const CAPTCHA_VERIFICATION_FAILED = "ReCaptcha Verification Is Failed.";

export function login(action) {

  try {
    Axios({
      url: `${API_URL_SW}/get-token`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        authorization: authorization(),
      }
    })
      .then(loginResponse => {
        loginResponse.data.rows && loginResponse.data.rows.length !== 0 && loginResponse.data.rows[0].message &&
          cookie.save("serviceToken", loginResponse.data.rows[0].message, { path: "/" });
        cookie.save("env", environment(), {
          path: "/"
        });
      })
      .catch(err => {
        if (err.toString().includes('401')) {
          Axios({
            url: "/token",
            method: "GET",
            headers: {
              "x-env": DP_ENV,
              "authorization": authorizationJwt()
            },
            data: 'grant_type=client_credentials'
          }).then(res => {
            res.data && cookie.save("token", res.data.access_token, { path: "/" });
            store.dispatch(actions.auth(action.payload.username, action.payload.password, "",""))
          })
            .catch(err => {
            })
        }
      });

  }
  catch (err) {
    //console.log(err,"lll")
  }
}

/**
 * Load supplier portal message at the startup
 * @param {*} action 
 */
export function* getPortalMessage(action) {
  Loader(true)
  try {
    Axios({
      url: `/get-supplier-message`,
      method: "GET",
      headers: {
        "x-env": DP_ENV
      },
    })
      .then(messageResponse => {                        
        store.dispatch({ type: LOGIN_MSG, payload: messageResponse.data ? messageResponse.data : "" });
        localStorage.setItem("loginMsg", messageResponse.data['lastModifiedDate']);
        localStorage.setItem("loginMsgDate", messageResponse.data['loginMessage']);
        Loader(false);
      }).catch(err => {        
        console.log(err);
        Loader(false);
      });

  } catch (error) {
    Loader(false);
    console.log(error);    
  }
}

export function getOutageMessages(action) {
  Loader(true)
  Axios.get("/dish/outage-messages")
    .then(response => {
      store.dispatch({ type: OUTAGE_MSG, payload: response.data });
      sessionStorage.setItem("outageMassages", JSON.stringify(response.data))
      Loader(false);
    }).catch(error => {
      console.error(error);
      Loader(false);
    });
}

/**
 * Auth login to APIC and OTCS
 * @param {} action 
 */
export function authLogin(action) {
  try {
    Axios({
      url: `/authentication`,
      method: "POST",
      headers: {
        "x-env": DP_ENV
      },
      data:{
        "password":action.payload.password,
        "userName":action.payload.username,
        "captchaToken":action.payload.captchaToken,
        "captchaBypassKey":action.payload.captchaBypassKey,
        "sssLogin":action.payload.sssLogin
      }
    })
      .then(loginResponse => {
        let apicToken = loginResponse.data['apic_token'];
        cookie.save("authToken", apicToken, { path: "/" });
        cookie.save("token", loginResponse.data['apic_token'], { path: "/" });
        cookie.save("jwt_token", loginResponse.data['jwt_token'], { path: "/" });
        cookie.save("accountExpirationTime", loginResponse.data['account_expiration_time'], { path: "/" });

        if (loginResponse.data) {
          let subvendorList = [];
          Axios({
            url: `${API_URL_SW}/get-vendornumbers-subvendors?vendorNumber=${action.payload.username}`,
            method: "GET",
            headers: {
              "Access-Control-Allow-Origin": "*",
              authorization: authorization(),
            }
          })
            .then(res => {
              if (res.data.rows[0].myRows && res.data.rows[0].myRows.length !== 0) {
                subvendorList = res.data.rows && res.data.rows[0].myRows && res.data.rows[0].myRows.length!==0 && res.data.rows[0].myRows.map(val => val.vendorNumber);
              }
              if(subvendorList.includes("INACTIVEVENDOR")){
                store.dispatch(actions.inactiveVendor("failed"));
              }else{
                store.dispatch(actions.loginSuccess(apicToken));
                localStorage.setItem("subvendorList", subvendorList);
                localStorage.setItem("authToken", apicToken)
                cookie.save("authToken", apicToken, {path: "/"});
                window.location.href = DASHBOARD;
                store.dispatch(actions.loginFailed("success"));
                
              }
            })
            .catch(err => {
              if(err.toString().includes('401')){
                Axios({
                  url:"/token",
                  method: "GET",
                  headers: {
                    "x-env": DP_ENV,
                    "authorization": authorizationJwt()
                  },
                  data:'grant_type=client_credentials'
                }).then(res => {
                   res.data && cookie.save("token", res.data.access_token, {path: "/"});
                   store.dispatch(actions.auth(action.payload.username,action.payload.password,action.payload.captchaToken,action.payload.captchaBypassKey))
                  })
                  .catch(err => {
                    store.dispatch(actions.serviceCallFailed("failed"));
                  })
                }
                 else
                // store.dispatch(actions.inactiveVendor("failed"));
                store.dispatch(actions.loginSuccess(apicToken));
                localStorage.setItem("subvendorList", subvendorList);
                localStorage.setItem("authToken", apicToken)
                cookie.save("authToken", apicToken, {path: "/"});
                window.location.href = DASHBOARD;
                store.dispatch(actions.loginFailed("success"));
            });
      }

    })
    .catch(err => {
    if(err.toString().includes('401')){
      Axios({
        url:"/token",
        method: "GET",
        headers: {
          "x-env": DP_ENV,
          "authorization": authorizationJwt()
        },
        data:'grant_type=client_credentials'
      }).then(res => {
         res.data && cookie.save("token", res.data.access_token, {path: "/"});
         store.dispatch(actions.auth(action.payload.username,action.payload.password,action.payload.captchaToken,action.payload.captchaBypassKey))
        })
        .catch(err => {
          store.dispatch(actions.serviceCallFailed("failed"));
        })
    }else{
      if (err.message === "Network Error") {
        store.dispatch(actions.serviceCallFailed("failed"));
      //  console.log(`Problem with network ${test}`);
      }else if(err.response && err.response.data==CAPTCHAFAILED_MESSAGE){
        store.dispatch(actions.captchaFailed("failed"));
      }
      else if(err.response && err.response.data=='CHANGE_AFTER_RESET'){
        store.dispatch(actions.loginFailedChangeAfterReset("failed"));
      }
      else if(err.response && err.response.data=='PASSWORD_EXPIRED'){
        store.dispatch(actions.loginFailedPasswordExpired("failed"));
      }
      else if(err.response && err.response.data=='ACCOUNT_LOCKED'){
        store.dispatch(actions.loginFailedAccountLocked("failed"));
      }
       else {
       // console.log(`Wrong credentials ${test}`);
        store.dispatch(actions.loginFailed("failed"));
      }   
    }
    });
  } catch (err) {
    store.dispatch(actions.serviceCallFailed("failed"));
  }
}
